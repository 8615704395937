import React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Section from "../components/Section";
import Seo from "../components/SEO";

const Card = ({ title, text, link }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer nofollow"
    aria-label={title}
    className="hover:shadow-lg p-4 transition rounded prose"
  >
    <h2>{title}</h2>
    <p>{text}</p>
    <p>
      <b>{link}</b>
    </p>
  </a>
);

const Feed = ({ location }) => {
  return (
    <Layout>
      <Section>
        <Seo
          title="compAIR - Member Area"
          description="Wiki, Forum, ..."
          url={location.pathname}
          section="feed"
        />
        <PageHeader
          title="Member Area"
          tagline="Private area for members only"
          desc="Services are provided as part of the competition and are available to all teams. More will be available here, shortly before / during the
          competition days."
        />
        <div className="grid lg:grid-cols-2 gap-8 mx-auto w-2/3 mt-16">
          <Card
            title="Forum"
            link="https://forum.comp-air.at"
            text="If you have questions about the competition or the robot, please use our forum."
          />
          <Card
            title="Wiki"
            link="https://wiki.comp-air.at"
            text="This wiki contains all the information you need to participate in the compAIR 2021."
          />
          <Card
            title="Upload"
            link="https://upload.comp-air.at"
            text="The upload plattform contains everything necessary to upload your code during the competition. Furthermore, your videos and code-reviews are processed here."
          />
          <Card
            title="compAIR Lib"
            link="https://lib.comp-air.at"
            text="The compAIR Lib provides an API to programm the official competition Robot."
          />
          <Card
            title="Grafana"
            link="https://grafana.comp-air.at"
            text="Grafana provides an easy way to monitor the inner workings of your robot(s). Data is graphed live and stored for at least a month."
          />
        </div>
      </Section>
    </Layout>
  );
};

export default Feed;
